/* eslint-disable camelcase */
import type { Axios, AxiosInstance, InternalAxiosRequestConfig } from 'axios'

import { CURRENT_ENVIRONMENT } from '@/constants/environment.constant.ts'
import type { GrantType, OAuth2ClientTokensWithExpiration } from '@/http/oAuth2Client'
import { OAuth2Client, TokenStore } from '@/http/oAuth2Client'
import router from '@/router/appRouter.composable'
import ROUTE_NAME from '@/router/routeName'
import { logError } from '@/utils/logger.util'

interface OAuth2VueClientOptions {
	axios: Axios | AxiosInstance
	clientId: string
	clientSecret: string
	tokenEndpoint: string
	scopes?: string[]
	/*
	 * If offline is true, the client will work without a real login
	 */
	offline?: boolean
}

export class OAuth2VueClient {
	private oAuthFactory: OAuth2Client
	private client: TokenStore | null = null
	private readonly offline: boolean

	constructor(private readonly options: OAuth2VueClientOptions) {
		this.oAuthFactory = new OAuth2Client({
			axios: options.axios,
			clientId: options.clientId,
			clientSecret: options.clientSecret,
			tokenEndpoint: options.tokenEndpoint,
		})

		this.offline = options.offline ?? false

		this.client = this.createClient()
	}

	private createClient(tokens?: OAuth2ClientTokensWithExpiration): TokenStore {
		const { axios, clientId, clientSecret, tokenEndpoint } = this.options

		return new TokenStore(
			{
				axios,
				clientId,
				clientSecret,
				tokenEndpoint,
			},
			tokens
		)
	}

	public getClient(): TokenStore | null {
		return this.client
	}

	private removeClient(): void {
		this.client?.clearTokens()
		this.client = null
	}

	public isOffline(): boolean {
		return this.offline
	}

	public loginOffline(): void {
		this.client?.setTokens({
			access_token: '',
			expires_at: 0,
			expires_in: 0,
			refresh_token: '',
			scope: '',
			token_type: '',
		})
	}

	public async login(username: string, password: string): Promise<void> {
		if (this.options.offline) {
			this.loginOffline()
			return
		}

		const tokenStore = await this.oAuthFactory.loginPassword(username, password)
		this.client = this.createClient(tokenStore.getTokens())
	}

	public async loginAuthorization(code: string, state: string, grantType: GrantType): Promise<void> {
		if (this.options.offline) {
			this.loginOffline()
			return
		}

		const tokenStore = await this.oAuthFactory.loginAuthorization(code, state, grantType)
		this.client = this.createClient(tokenStore.getTokens())
	}

	public async logout(): Promise<void> {
		this.removeClient()
	}

	public isLoggedIn(): boolean {
		if (CURRENT_ENVIRONMENT === 'e2e') {
			return true
		}

		if (this.options.offline) {
			return true
		}

		const client = this.getClient()
		return client?.getTokens() != null
	}
}

export async function addAuthorizationHeader(
	oAuthClient: OAuth2VueClient,
	config: InternalAxiosRequestConfig<unknown>
): Promise<InternalAxiosRequestConfig<unknown>> {
	const client = oAuthClient.getClient()

	if (client === null) {
		return config
	}

	if (oAuthClient.isOffline()) {
		return config
	}

	if (CURRENT_ENVIRONMENT === 'e2e') {
		return config
	}

	try {
		const token = await client.getAccessToken()

		config.headers.Authorization = `Bearer ${token}`
	} catch (error) {
		logError('Failed to get access token, logging out')
		await oAuthClient.logout()
		await router.push({ name: ROUTE_NAME.AUTH.LOGIN, params: { redirect: router.currentRoute.value.fullPath } })
	}

	return config
}
