<script lang="ts" setup>
import { computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

import AppIcon from '@/components/app/icon/AppIcon.vue'
import AppTooltip from '@/components/app/tooltip/AppTooltip.vue'
import type { ComponentShape, ComponentVariant } from '@/composables/variants/componentVariants.composable'
import { useComponentVariants } from '@/composables/variants/componentVariants.composable'
import type { Icon } from '@/models/icon.enum'
import type { DefaultThemeColor } from '@/themes/default/colors/defaultThemeColors.type'
import { firstUppercase } from '@/utils/string.util'

const variants = useComponentVariants()

export interface AppButtonProps {
	label?: string | null
	color?: DefaultThemeColor | null
	variant?: ComponentVariant
	shape?: ComponentShape
	size?: 'lg' | 'md' | 'sm' | 'xl' | 'xs'
	manualContentSize?: boolean
	isLoading?: boolean
	tooltip?: string
	isDisabled?: boolean
	to?: RouteLocationRaw
	prefixIcon?: Icon | null
	suffixIcon?: Icon | null
}

const props = defineProps<AppButtonProps>()

const emit = defineEmits<{
	'component:click': [data: Event]
}>()

const size = computed<'lg' | 'md' | 'sm' | 'xl' | 'xs'>(() => props.size ?? 'md')
const variant = computed<ComponentVariant>(() => props.variant ?? 'solid')
const color = computed<DefaultThemeColor>(() => props.color ?? 'lima')
const shape = computed<ComponentShape>(() => props.shape ?? 'default')
const isDisabled = computed<boolean>(() => props.isDisabled || props.isLoading)

const buttonComponentStyle = computed<string>(() => {
	let padding

	if (shape.value === 'default') {
		padding = 'py-[6px] px-[14px]'
	} else if (shape.value === 'circle') {
		padding = 'px-[4px]'
	} else {
		padding = 'py-2 px-2'
	}

	return (
		variants.getComponentStyle(variant.value, color.value, shape.value, props.isDisabled) +
		' filter hover:brightness-110 font-bold grid gap-[5px] ' +
		padding
	)
})

const textSize = computed<string>(() => {
	const sizes: Record<string, string> = {
		lg: 'text-lg',
		md: 'text-md',
		sm: 'text-sm',
	}
	return sizes[size.value]
})

const spinnerComponentStyle = computed<string>(() =>
	variant.value === 'solid' ? '!text-white' : `!text-${props.color}`
)

const buttonComponentType = computed<'button' | 'router-link'>(() => {
	if (props.to) {
		return 'router-link'
	}

	return 'button'
})

function onClick(event: Event): void {
	if (isDisabled.value) {
		return
	}

	emit('component:click', event)
}
</script>

<template>
	<component
		:is="buttonComponentType"
		:class="buttonComponentStyle"
		:disabled="isDisabled"
		:to="props.to"
		@click.stop="onClick"
	>
		<AppTooltip
			as-child
			:delay-duration="300"
			:value="props.tooltip"
		>
			<span
				class="flex items-center justify-center gap-x-2 whitespace-nowrap transition-opacity"
				:class="{ 'opacity-0': isLoading }"
			>
				<AppIcon
					v-if="prefixIcon"
					:icon="prefixIcon"
					:size="size"
				/>
				<span
					v-if="label"
					:class="textSize"
				>
					{{ firstUppercase(label, true) }}
				</span>
				<slot />
				<AppIcon
					v-if="suffixIcon"
					:icon="suffixIcon"
					:size="size"
				/>
			</span>
		</AppTooltip>

		<span
			v-if="isLoading"
			class="absolute flex h-full w-full items-center justify-center"
			data-test-id="loading-spinner"
		>
			<a
				class="pi pi-spinner pi-spin"
				:class="spinnerComponentStyle"
			/>
		</span>
	</component>
</template>
