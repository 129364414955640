import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const customersRoutes: RouteRecordRaw[] = [
	{
		component: () => import('@/modules/customers/views/CustomersView.vue'),
		name: ROUTE_NAME.CUSTOMERS.INDEX,
		path: '/customers',
		beforeEnter: permissionMiddleware([Permission.CUSTOMER_READ]),
	},
	{
		beforeEnter: permissionMiddleware([Permission.CUSTOMER_READ]),
		children: [
			{
				children: [
					{
						component: () =>
							import('@/modules/customers/views/detail/overview/CustomersDetailOverviewDashboardView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.OVERVIEW.DASHBOARD,
						path: 'dashboard',
					},
					{
						component: () =>
							import('@/modules/customers/views/detail/overview/CustomersDetailOverviewLogbooksView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.OVERVIEW.LOGBOOKS,
						beforeEnter: permissionMiddleware([Permission.LOGBOOK_READ]),
						path: 'logbook',
					},
				],
				component: () => import('@/modules/customers/views/detail/overview/CustomersDetailOverviewView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.OVERVIEW.INDEX,
				path: 'overview',
				redirect: { name: ROUTE_NAME.CUSTOMERS.DETAIL.OVERVIEW.DASHBOARD },
			},
			{
				children: [
					{
						component: () => import('@/modules/customers/views/detail/planning/CustomersDetailPlanningWeekView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.PLANNING.WEEK_PLANNING,
						path: 'week-planning',
					},
					{
						component: () =>
							import('@/modules/customers/views/detail/planning/CustomersDetailPlanningAvailabilitiesView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.PLANNING.AVAILABILITIES,
						path: 'availabilities',
					},
					{
						component: () =>
							import('@/modules/customers/views/detail/planning/CustomersDetailPlanningEmployeesView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.PLANNING.EMPLOYEES,
						path: 'employees',
					},
				],
				component: () => import('@/modules/customers/views/detail/planning/CustomersDetailPlanningView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.PLANNING.INDEX,
				path: 'planning',
				beforeEnter: permissionMiddleware([Permission.PLANNING_READ]),
				redirect: {
					name: ROUTE_NAME.CUSTOMERS.DETAIL.PLANNING.WEEK_PLANNING,
				},
			},
			{
				children: [
					{
						component: () =>
							import('@/modules/customers/views/detail/info/personal/CustomersDetailInfoPersonalView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.PERSONAL,
						path: 'personal',
					},
					{
						component: () =>
							import('@/modules/customers/views/detail/info/referral/CustomersDetailInfoReferralView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.REFERRAL,
						path: 'referral',
					},
					{
						component: () => import('@/modules/customers/views/detail/info/lead/CustomersDetailInfoLeadView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.LEAD,
						path: 'lead',
					},
					{
						component: () =>
							import('@/modules/customers/views/detail/info/cancellation/CustomersDetailInfoCancellationView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.CANCELLATION,
						path: 'cancellation',
					},
					{
						component: () =>
							import('@/modules/customers/views/detail/info/rejection/CustomersDetailInfoRejectionView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.REJECTION,
						path: 'rejection',
					},
				],
				component: () => import('@/modules/customers/views/detail/info/CustomersDetailInfoView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.INDEX,
				path: 'details',
				redirect: { name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.PERSONAL },
			},
			{
				children: [
					{
						component: () =>
							import(
								'@/modules/customers/views/detail/contract/subscription/CustomerDetailContractSubscriptionView.vue'
							),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.CONTRACT.SUBSCRIPTION,
						path: 'subscription',
					},
					{
						component: () =>
							import(
								'@/modules/customers/views/detail/contract/terms-of-service/CustomerDetailContractTermsOfServiceView.vue'
							),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.CONTRACT.TERMS_OF_SERVICE,
						path: 'terms-of-service',
					},
				],
				component: () => import('@/modules/customers/views/detail/contract/CustomersDetailContractView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.CONTRACT.INDEX,
				path: 'contract',
				redirect: { name: ROUTE_NAME.CUSTOMERS.DETAIL.CONTRACT.SUBSCRIPTION },
			},
			{
				component: () => import('@/modules/customers/views/detail/absences/CustomersDetailAbsencesView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.ABSENCES.INDEX,
				path: 'absence',
			},
			{
				component: () => import('@/modules/customers/views/detail/documents/CustomersDetailDocumentsView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.DOCUMENTS.INDEX,
				path: 'documents',
			},
			{
				children: [
					{
						component: () =>
							import('@/modules/customers/views/detail/notifications/CustomersDetailNotificationsClaimsView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.NOTIFICATIONS.DAMAGE_CLAIMS,
						path: 'damage-claims',
					},
					{
						component: () =>
							import('@/modules/customers/views/detail/notifications/CustomersDetailNotificationsAccidentsView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.NOTIFICATIONS.WORK_ACCIDENTS,
						path: 'work-accidents',
					},
				],
				component: () => import('@/modules/customers/views/detail/notifications/CustomersDetailNotificationsView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.NOTIFICATIONS.INDEX,
				path: 'notification',
				redirect: {
					name: ROUTE_NAME.CUSTOMERS.DETAIL.NOTIFICATIONS.DAMAGE_CLAIMS,
				},
			},
			{
				children: [
					{
						component: () =>
							import(
								'@/modules/customers/views/detail/service-checks/shortages/CustomersDetailServiceChecksShortagesView.vue'
							),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.SERVICE_CHECKS.SHORTAGES,
						path: 'shortages',
					},
					{
						component: () => import('@/views/UnderConstructionView.vue'),
						name: ROUTE_NAME.CUSTOMERS.DETAIL.SERVICE_CHECKS.CHECKS,
						path: 'cheques',
					},
				],
				component: () => import('@/modules/customers/views/detail/service-checks/CustomersDetailServiceChecksView.vue'),
				name: ROUTE_NAME.CUSTOMERS.DETAIL.SERVICE_CHECKS.INDEX,
				path: 'service-checks',
				beforeEnter: permissionMiddleware([Permission.CHEQUE_READ]),
				redirect: {
					name: ROUTE_NAME.CUSTOMERS.DETAIL.SERVICE_CHECKS.SHORTAGES,
				},
			},
		],
		component: () => import('@/modules/customers/views/detail/CustomersDetailView.vue'),
		name: ROUTE_NAME.CUSTOMERS.DETAIL.INDEX,
		path: '/customers/:uuid',
		redirect: { name: ROUTE_NAME.CUSTOMERS.DETAIL.OVERVIEW.INDEX },
	},
	{
		beforeEnter: permissionMiddleware([Permission.CUSTOMER_READ]),
		component: () => import('@/modules/customers/views/prospect/CustomersProspectView.vue'),
		name: ROUTE_NAME.CUSTOMERS.PROSPECT.INDEX,
		path: '/customers/:uuid/prospect',
	},
]
