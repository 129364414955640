import { computed } from 'vue'

import type { DataItem } from '@/models/dataItem.type'
import { i18nPlugin } from '@/plugins/i18n.plugin'

export enum Permission {
	ADMIN = 'admin',
	READ_ONLY = 'read_only',

	// Departments
	DEPARTMENT_READ_ALL = 'department.read_all',
	DEPARTMENT_CREATE = 'department.create',
	DEPARTMENT_READ = 'department.read',
	DEPARTMENT_UPDATE = 'department.update',
	DEPARTMENT_DELETE = 'department.delete',

	// ADMIN_READ = 'admin.read',
	// ADMIN_WRITE = 'admin.write',

	// // Availabilities
	// AVAILABILITY_CREATE = 'availability.create',
	// AVAILABILITY_READ = 'availability.read',
	// AVAILABILITY_UPDATE = 'availability.update',
	// AVAILABILITY_DELETE = 'availability.delete',

	// Cheques
	CHEQUE_CREATE = 'cheque.create',
	CHEQUE_READ = 'cheque.read',
	CHEQUE_UPDATE = 'cheque.update',
	CHEQUE_DELETE = 'cheque.delete',
	CHEQUE_SETTINGS = 'cheque.settings',

	// Contract
	EMPLOYEE_CONTRACT_CREATE = 'employee_contract.create',
	EMPLOYEE_CONTRACT_READ = 'employee_contract.read',
	EMPLOYEE_CONTRACT_UPDATE = 'employee_contract.update',
	EMPLOYEE_CONTRACT_DELETE = 'employee_contract.delete',

	// Employee Contract In Past
	EMPLOYEE_CONTRACT_IN_PAST_CREATE = 'employee_contract.in_past_create',

	// Customer
	CUSTOMER_CREATE = 'customer.create',
	CUSTOMER_READ = 'customer.read',
	CUSTOMER_UPDATE = 'customer.update',
	CUSTOMER_DELETE = 'customer.delete',

	// Customer set custom invoice price
	CUSTOMER_SET_CUSTOM_INVOICE_PRICE = 'customer.set_custom_invoice_price',

	// Customer Reference
	CUSTOMER_REFERENCE_TAKEOVER = 'customer.reference_takeover',

	// Debtors
	DEBTOR_READ = 'debtor.read',
	DEBTOR_UPDATE = 'debtor.update',
	DEBTOR_CLOSE = 'debtor.close',

	// Employee
	EMPLOYEE_CREATE = 'employee.create',
	EMPLOYEE_READ = 'employee.read',
	EMPLOYEE_UPDATE = 'employee.update',
	EMPLOYEE_DELETE = 'employee.delete',

	// Employee absence
	EMPLOYEE_ABSENCE_STRIKE = 'employee_absence.strike',

	// Blog
	BLOG_CREATE = 'blog.create',
	BLOG_READ = 'blog.read',
	BLOG_UPDATE = 'blog.update',
	BLOG_DELETE = 'blog.delete',

	// News
	NEWS_CREATE = 'news.create',
	NEWS_READ = 'news.read',
	NEWS_UPDATE = 'news.update',
	NEWS_DELETE = 'news.delete',

	// Performances
	// PERFORMANCE_READ = 'performance.read',
	// PERFORMANCE_UPDATE = 'performance.update',
	// PERFORMANCE_DELETE = 'performance.delete',
	PERFORMANCE_FUND_MANUALLY = 'performance.fund_manually',

	// Planning
	PLANNING_CREATE = 'planning.create',
	PLANNING_READ = 'planning.read',
	PLANNING_UPDATE = 'planning.update',
	PLANNING_DELETE = 'planning.delete',
	PLANNING_READ_PAST = 'planning.read_past',
	PLANNING_UPDATE_PAST_SALARY_PERIOD = 'planning.update_past_salary_period',

	// Timetables
	TIMETABLE_CREATE = 'timetable.create',
	TIMETABLE_READ = 'timetable.read',
	TIMETABLE_UPDATE = 'timetable.update',
	TIMETABLE_DELETE = 'timetable.delete',

	// todos
	TODO_CREATE = 'todo.create',
	TODO_READ = 'todo.read',
	TODO_UPDATE = 'todo.update',
	TODO_DELETE = 'todo.delete',

	// Mail tokens
	MAIL_TOKEN_CREATE = 'mail_token.create',

	// Dimona
	DIMONA_RETRY_UPLOAD = 'dimona.retry_upload',
	DIMONA_LOGS_READ = 'dimona_logs.read',

	DIMONA_DECLARATION_READ = 'dimona_declaration.read',
	DIMONA_DECLARATION_CREATE = 'dimona_declaration.create',
	DIMONA_DECLARATION_DELETE = 'dimona_declaration.delete',

	// Accidents
	ACCIDENT_CREATE = 'accident.create',
	ACCIDENT_READ = 'accident.read',
	ACCIDENT_UPDATE = 'accident.update',
	ACCIDENT_UPDATE_STATUS = 'accident.update_status',
	ACCIDENT_UPDATE_CONCEPT = 'accident.update_concept',
	ACCIDENT_DELETE = 'accident.delete',
	ACCIDENT_DELETE_CONCEPT = 'accident.delete_concept',

	// Logbooks
	LOGBOOK_CREATE = 'logbook.create',
	LOGBOOK_READ = 'logbook.read',

	// Claims
	CLAIM_CREATE = 'claim.create',
	CLAIM_READ = 'claim.read',
	CLAIM_UPDATE = 'claim.update',
	CLAIM_DELETE = 'claim.delete',

	// Files
	FILE_DELETE = 'file.delete',

	SALARY_READ = 'salary.read',
	SALARY_UPDATE = 'salary.update',

	// Remittances
	REMITTANCE_READ = 'remittance.read',
	REMITTANCE_UPDATE = 'remittance.update',
	REMITTANCE_SUBMIT = 'remittance.submit',

	// Chat
	CHAT_READ = 'chat.read',
	CHAT_WRITE = 'chat.write',

	// Anomalies
	ANOMALY_READ = 'anomaly.read',
	ANOMALY_VALIDATE = 'anomaly.validate',
	ANOMALY_RESOLVE = 'anomaly.resolve',

	// Subscriptions
	SUBSCRIPTION_SETTINGS_READ = 'subscription_settings.read',
	SUBSCRIPTION_SETTINGS_CREATE = 'subscription_settings.create',
	SUBSCRIPTION_SETTINGS_DELETE = 'subscription_settings.delete',
	SUBSCRIPTION_SETTINGS_UPDATE = 'subscription_settings.update',

	// Settings
	SETTINGS_READ = 'settings.read',
	SETTINGS_WRITE = 'settings.write',
	SETTINGS_DELETE = 'settings.delete',
	SETTINGS_UPDATE = 'settings.update',

	// employee economic unemployment
	EMPLOYEE_ECONOMIC_UNEMPLOYMENT_READ = 'employee_economic_unemployment.read',
	EMPLOYEE_ECONOMIC_UNEMPLOYMENT_WRITE = 'employee_economic_unemployment.write',
	EMPLOYEE_ECONOMIC_UNEMPLOYMENT_IN_PAST_WRITE = 'employee_economic_unemployment.in_past_write',
	EMPLOYEE_ECONOMIC_UNEMPLOYMENT_DELETE = 'employee_economic_unemployment.delete',
	EMPLOYEE_ECONOMIC_UNEMPLOYMENT_UPDATE = 'employee_economic_unemployment.update',

	// employee fleet
	EMPLOYEE_FLEET_LEASING_UPDATE = 'employee_fleet_leasing.update',
	EMPLOYEE_FLEET_LEASING_DELETE = 'employee_fleet_leasing.delete',

	//  Info Bubbles
	INFO_BUBBLES_READ = 'info_bubbles.read',
	INFO_BUBBLES_WRITE = 'info_bubbles.write',
	INFO_BUBBLES_DELETE = 'info_bubbles.delete',
	INFO_BUBBLES_UPDATE = 'info_bubbles.update',

	// FAQ
	FAQ_READ = 'faq.read',
	FAQ_WRITE = 'faq.write',
	FAQ_DELETE = 'faq.delete',
	FAQ_UPDATE = 'faq.update',

	// Roles
	ROLES_READ = 'roles.read',
	ROLES_WRITE = 'roles.write',
	ROLES_DELETE = 'roles.delete',
	ROLES_UPDATE = 'roles.update',

	// Advance Amount
	ADVANCE_AMOUNT_CREATE = 'advance_amount.create',
	ADVANCE_AMOUNT_READ = 'advance_amount.read',
	ADVANCE_AMOUNT_UPDATE = 'advance_amount.update',
	ADVANCE_AMOUNT_DELETE = 'advance_amount.delete',

	// Barema
	BAREMA_CREATE = 'barema.create',
	BAREMA_READ = 'barema.read',
	BAREMA_UPDATE = 'barema.update',
	BAREMA_DELETE = 'barema.delete',

	// Economic Unemployment Periods
	ECONOMIC_UNEMPLOYMENT_PERIODS_CREATE = 'economic_unemployment_periods.create',
	ECONOMIC_UNEMPLOYMENT_PERIODS_READ = 'economic_unemployment_periods.read',
	ECONOMIC_UNEMPLOYMENT_PERIODS_UPDATE = 'economic_unemployment_periods.update',
	ECONOMIC_UNEMPLOYMENT_PERIODS_DELETE = 'economic_unemployment_periods.delete',

	ECONOMIC_UNEMPLOYMENT_DECLARATIONS_READ = 'economic_unemployment_declarations.read',
	ECONOMIC_UNEMPLOYMENT_DECLARATIONS_WRITE = 'economic_unemployment_declarations.write',
	ECONOMIC_UNEMPLOYMENT_DECLARATIONS_DELETE = 'economic_unemployment_declarations.delete',
	ECONOMIC_UNEMPLOYMENT_DECLARATIONS_UPDATE = 'economic_unemployment_declarations.update',

	ECONOMIC_UNEMPLOYMENT_VALIDATION_BOOK_READ = 'economic_unemployment_validation_book.read',
	ECONOMIC_UNEMPLOYMENT_VALIDATION_BOOK_WRITE = 'economic_unemployment_validation_book.write',
	ECONOMIC_UNEMPLOYMENT_VALIDATION_BOOK_DELETE = 'economic_unemployment_validation_book.delete',
	ECONOMIC_UNEMPLOYMENT_VALIDATION_BOOK_UPDATE = 'economic_unemployment_validation_book.update',

	// Holiday
	HOLIDAY_CREATE = 'holiday.create',
	HOLIDAY_READ = 'holiday.read',
	HOLIDAY_UPDATE = 'holiday.update',
	HOLIDAY_DELETE = 'holiday.delete',

	// Permission
	PERMISSION_CREATE = 'permission.create',
	PERMISSION_READ = 'permission.read',
	PERMISSION_UPDATE = 'permission.update',
	PERMISSION_DELETE = 'permission.delete',

	// Staff
	STAFF_CREATE = 'staff.create',
	STAFF_READ = 'staff.read',
	STAFF_UPDATE = 'staff.update',
	STAFF_DELETE = 'staff.delete',

	// Payroll
	PAYROLL_CREATE = 'payroll.create',
	PAYROLL_READ = 'payroll.read',
	PAYROLL_UPDATE = 'payroll.update',
	PAYROLL_DELETE = 'payroll.delete',

	// Office
	OFFICE_CREATE = 'office.create',
	OFFICE_READ = 'office.read',
	OFFICE_UPDATE = 'office.update',
	OFFICE_DELETE = 'office.delete',

	// Origin Company
	ORIGIN_COMPANY_CREATE = 'origin_company.create',
	ORIGIN_COMPANY_READ = 'origin_company.read',
	ORIGIN_COMPANY_UPDATE = 'origin_company.update',
	ORIGIN_COMPANY_DELETE = 'origin_company.delete',

	// District
	DISTRICT_CREATE = 'district.create',
	DISTRICT_READ = 'district.read',
	DISTRICT_UPDATE = 'district.update',
	DISTRICT_DELETE = 'district.delete',

	// Benefit code
	BENEFIT_CODE_CREATE = 'benefit_code.create',
	BENEFIT_CODE_READ = 'benefit_code.read',
	BENEFIT_CODE_UPDATE = 'benefit_code.update',
	BENEFIT_CODE_DELETE = 'benefit_code.delete',

	// Absence code
	ABSENCE_CODE_CREATE = 'absence_code.create',
	ABSENCE_CODE_READ = 'absence_code.read',
	ABSENCE_CODE_UPDATE = 'absence_code.update',
	ABSENCE_CODE_DELETE = 'absence_code.delete',

	// Subsidy
	SUBSIDY_CREATE = 'subsidy.create',
	SUBSIDY_READ = 'subsidy.read',
	SUBSIDY_UPDATE = 'subsidy.update',
	SUBSIDY_DELETE = 'subsidy.delete',

	// Referral premium
	REFERRAL_PREMIUM_CREATE = 'referral_premium.create',
	REFERRAL_PREMIUM_READ = 'referral_premium.read',
	REFERRAL_PREMIUM_UPDATE = 'referral_premium.update',
	REFERRAL_PREMIUM_DELETE = 'referral_premium.delete',
	REFERRAL_PREMIUM_TO_ONGOING = 'referral_premium.to_ongoing',

	// Matching center
	MATCHING_CENTER_CREATE = 'matching_center.create',
	MATCHING_CENTER_READ = 'matching_center.read',
	MATCHING_CENTER_UPDATE = 'matching_center.update',
	MATCHING_CENTER_DELETE = 'matching_center.delete',

	// EMPLOYEE ABSENCE COUNTER
	EMPLOYEE_ABSENCE_COUNTER_CREATE = 'employee_absence_counter.create',
	EMPLOYEE_ABSENCE_COUNTER_READ = 'employee_absence_counter.read',
	EMPLOYEE_ABSENCE_COUNTER_UPDATE = 'employee_absence_counter.update',
	EMPLOYEE_ABSENCE_COUNTER_DELETE = 'employee_absence_counter.delete',

	// EMPLOYEE_PAID_LEAVE_ABSENCE_COUNTER
	EMPLOYEE_PAID_LEAVE_ABSENCE_COUNTER_CREATE = 'employee_paid_leave_absence_counter.create',
	EMPLOYEE_PAID_LEAVE_ABSENCE_COUNTER_READ = 'employee_paid_leave_absence_counter.read',
	EMPLOYEE_PAID_LEAVE_ABSENCE_COUNTER_UPDATE = 'employee_paid_leave_absence_counter.update',
	EMPLOYEE_PAID_LEAVE_ABSENCE_COUNTER_DELETE = 'employee_paid_leave_absence_counter.delete',

	// KPI

	KPI_READ = 'kpi.read',
}

export enum PermissionGroup {
	ADMIN = 'admin',
	READ_ONLY = 'read_only',
	BLOG = 'blog',
	DEPARTMENTS = 'departments',
	AVAILABILITY = 'availability',
	CHEQUE = 'cheque',
	EMPLOYEE_CONTRACT = 'employee_contract',
	EMPLOYEE_ABSENCE = 'employee_absence',
	CUSTOMER = 'customer',
	DEBTOR = 'debtor',
	EMPLOYEE = 'employee',
	NEWS = 'news',
	PERFORMANCE = 'performance',
	PLANNING = 'planning',
	TIMETABLE = 'timetable',
	TODO = 'todo',
	MAIL_TOKEN = 'mail_token',
	DIMONA = 'dimona',
	DIMONA_LOGS = 'dimona_logs',
	DIMONA_DECLARATION = 'dimona_declaration',
	ACCIDENT = 'accident',
	LOGBOOK = 'logbook',
	CLAIM = 'claim',
	FILE = 'file',
	SALARY = 'salary',
	REMITTANCE = 'remittance',
	CHAT = 'chat',
	ANOMALY = 'anomaly',
	SUBSCRIPTION = 'subscription',
	SETTINGS = 'settings',
	ECONOMIC_UNEMPLOYMENT_PERIODS = 'economic_unemployment_periods',
	ECONOMIC_UNEMPLOYMENT_DECLARATIONS = 'economic_unemployment_declarations',
	ECONOMIC_UNEMPLOYMENT_VALIDATION_BOOK = 'economic_unemployment_validation_book',
	EMPLOYEE_ECONOMIC_UNEMPLOYMENT = 'employee_economic_unemployment',
	EMPLOYEE_FLEET_LEASING = 'employee_fleet_leasing',
	EMPLOYEE_ABSENCE_COUNTER = 'employee_absence_counter',
	EMPLOYEE_PAID_LEAVE_ABSENCE_COUNTER = 'employee_paid_leave_absence_counter',
	INFO_BUBBLES = 'info_bubbles',
	FAQ = 'faq',
	ROLES = 'roles',
	ADVANCE_AMOUNT = 'advance_amount',
	BAREMA = 'barema',
	HOLIDAY = 'holiday',
	PERMISSION = 'permission',
	STAFF = 'staff',
	PAYROLL = 'payroll',
	OFFICE = 'office',
	DISTRICT = 'district',
	BENEFIT_CODE = 'benefit_code',
	ABSENCE_CODE = 'absence_code',
	SUBSIDY = 'subsidy',
	REFERRAL_PREMIUM = 'referral_premium',
	MATCHING_CENTER = 'matching_center',
	SUBSCRIPTION_SETTINGS = 'subscription_settings',
	KPI = 'kpi',
	ORIGIN_COMPANY = 'origin_company',
}

export const PERMISSION_GROUP_OPTIONS = computed<DataItem<PermissionGroup>[]>(() => {
	const { t } = i18nPlugin.global

	return [
		{ label: t('permission.admin'), value: PermissionGroup.ADMIN },
		{ label: t('permission.read_only'), value: PermissionGroup.READ_ONLY },
		{ label: t('permission.blog'), value: PermissionGroup.BLOG },
		{ label: t('permission.departments'), value: PermissionGroup.DEPARTMENTS },
		{ label: t('permission.availability'), value: PermissionGroup.AVAILABILITY },
		{ label: t('permission.cheque'), value: PermissionGroup.CHEQUE },
		{ label: t('permission.employee_contract'), value: PermissionGroup.EMPLOYEE_CONTRACT },
		{ label: t('permission.customer'), value: PermissionGroup.CUSTOMER },
		{ label: t('permission.debtor'), value: PermissionGroup.DEBTOR },
		{ label: t('permission.employee_absence'), value: PermissionGroup.EMPLOYEE_ABSENCE },
		{ label: t('permission.employee'), value: PermissionGroup.EMPLOYEE },
		{ label: t('permission.employee_fleet_leasing'), value: PermissionGroup.EMPLOYEE_FLEET_LEASING },
		{ label: t('permission.employee_absence_counter'), value: PermissionGroup.EMPLOYEE_ABSENCE_COUNTER },
		{
			label: t('permission.employee_paid_leave_absence_counter'),
			value: PermissionGroup.EMPLOYEE_PAID_LEAVE_ABSENCE_COUNTER,
		},
		{ label: t('permission.news'), value: PermissionGroup.NEWS },
		{ label: t('permission.performance'), value: PermissionGroup.PERFORMANCE },
		{ label: t('permission.planning'), value: PermissionGroup.PLANNING },
		{ label: t('permission.timetable'), value: PermissionGroup.TIMETABLE },
		{ label: t('permission.todo'), value: PermissionGroup.TODO },
		{ label: t('permission.mail_token'), value: PermissionGroup.MAIL_TOKEN },
		{ label: t('permission.dimona'), value: PermissionGroup.DIMONA },
		{ label: t('permission.dimona_logs'), value: PermissionGroup.DIMONA_LOGS },
		{ label: t('permission.dimona_declaration'), value: PermissionGroup.DIMONA_DECLARATION },
		{ label: t('permission.accident'), value: PermissionGroup.ACCIDENT },
		{ label: t('permission.logbook'), value: PermissionGroup.LOGBOOK },
		{ label: t('permission.claim'), value: PermissionGroup.CLAIM },
		{ label: t('permission.file'), value: PermissionGroup.FILE },
		{ label: t('permission.kpi'), value: PermissionGroup.KPI },
		{ label: t('permission.salary'), value: PermissionGroup.SALARY },
		{ label: t('permission.remittance'), value: PermissionGroup.REMITTANCE },
		{ label: t('permission.chat'), value: PermissionGroup.CHAT },
		{ label: t('permission.anomaly'), value: PermissionGroup.ANOMALY },
		{ label: t('permission.subscription'), value: PermissionGroup.SUBSCRIPTION },
		{ label: t('permission.settings'), value: PermissionGroup.SETTINGS },
		{ label: t('permission.economic_unemployment_periods'), value: PermissionGroup.ECONOMIC_UNEMPLOYMENT_PERIODS },
		{ label: t('permission.employee_economic_unemployment'), value: PermissionGroup.EMPLOYEE_ECONOMIC_UNEMPLOYMENT },
		{
			label: t('permission.economic_unemployment_declarations'),
			value: PermissionGroup.ECONOMIC_UNEMPLOYMENT_DECLARATIONS,
		},
		{
			label: t('permission.economic_unemployment_validation_book'),
			value: PermissionGroup.ECONOMIC_UNEMPLOYMENT_VALIDATION_BOOK,
		},
		{ label: t('permission.info_bubbles'), value: PermissionGroup.INFO_BUBBLES },
		{ label: t('permission.faq'), value: PermissionGroup.FAQ },
		{ label: t('permission.roles'), value: PermissionGroup.ROLES },
		{ label: t('permission.advance_amount'), value: PermissionGroup.ADVANCE_AMOUNT },
		{ label: t('permission.barema'), value: PermissionGroup.BAREMA },
		{ label: t('permission.holiday'), value: PermissionGroup.HOLIDAY },
		{ label: t('permission.permission'), value: PermissionGroup.PERMISSION },
		{ label: t('permission.staff'), value: PermissionGroup.STAFF },
		{ label: t('permission.payroll'), value: PermissionGroup.PAYROLL },
		{ label: t('permission.office'), value: PermissionGroup.OFFICE },
		{ label: t('permission.district'), value: PermissionGroup.DISTRICT },
		{ label: t('permission.benefit_code'), value: PermissionGroup.BENEFIT_CODE },
		{ label: t('permission.absence_code'), value: PermissionGroup.ABSENCE_CODE },
		{ label: t('permission.subsidy'), value: PermissionGroup.SUBSIDY },
		{ label: t('permission.referral_premium'), value: PermissionGroup.REFERRAL_PREMIUM },
		{ label: t('permission.matching_center'), value: PermissionGroup.MATCHING_CENTER },
		{ label: t('permission.subscription_settings'), value: PermissionGroup.SUBSCRIPTION_SETTINGS },
		{ label: t('permission.origin_company'), value: PermissionGroup.ORIGIN_COMPANY },
	]
})

export function getPermissionGroupLabel(group: PermissionGroup | string): string | null {
	return PERMISSION_GROUP_OPTIONS.value.find((option) => option.value === group)?.label || null
}

export enum PermissionAction {
	CREATE = 'create',
	READ = 'read',
	UPDATE = 'update',
	DELETE = 'delete',
	WRITE = 'write',
	SETTINGS = 'settings',
	STRIKE = 'strike',
	IN_PAST_CREATE = 'in_past_create',
	IN_PAST_WRITE = 'in_past_write',
	SET_CUSTOM_INVOICE_PRICE = 'set_custom_invoice_price',
	REFERENCE_TAKEOVER = 'reference_takeover',
	CLOSE = 'close',
	FUND_MANUALLY = 'fund_manually',
	UPDATE_PAST_SALARY_PERIOD = 'update_past_salary_period',
	RETRY_UPLOAD = 'retry_upload',
	UPDATE_STATUS = 'update_status',
	UPDATE_CONCEPT = 'update_concept',
	DELETE_CONCEPT = 'delete_concept',
	SUBMIT = 'submit',
	VALIDATE = 'validate',
	RESOLVE = 'resolve',
	READ_ALL = 'read_all',
	DELETE_PAST_CANCEL_DATE = 'delete_past_cancel_date',
	DELETE_FIRST_OF_MONTH = 'delete_first_of_month',
	READ_PAST = 'read_past',
	TO_ONGOING = 'to_ongoing',
	IGNORE_MAX_PERIOD_IN_TEMPORARY_CONTRACT = 'ignore_max_period_in_temporary_contract',
}

export const PERMISSION_ACTION_OPTIONS = computed<DataItem<PermissionAction>[]>(() => {
	const { t } = i18nPlugin.global

	return [
		{ label: t('permission.action.create'), value: PermissionAction.CREATE },
		{ label: t('permission.action.read'), value: PermissionAction.READ },
		{ label: t('permission.action.update'), value: PermissionAction.UPDATE },
		{ label: t('permission.action.delete'), value: PermissionAction.DELETE },
		{ label: t('permission.action.write'), value: PermissionAction.WRITE },
		{ label: t('permission.action.strike'), value: PermissionAction.STRIKE },
		{ label: t('permission.action.settings'), value: PermissionAction.SETTINGS },
		{ label: t('permission.action.in_past_create'), value: PermissionAction.IN_PAST_CREATE },
		{ label: t('permission.action.in_past_write'), value: PermissionAction.IN_PAST_WRITE },
		{ label: t('permission.action.set_custom_invoice_price'), value: PermissionAction.SET_CUSTOM_INVOICE_PRICE },
		{ label: t('permission.action.reference_takeover'), value: PermissionAction.REFERENCE_TAKEOVER },
		{ label: t('permission.action.close'), value: PermissionAction.CLOSE },
		{ label: t('permission.action.fund_manually'), value: PermissionAction.FUND_MANUALLY },
		{ label: t('permission.action.update_past_salary_period'), value: PermissionAction.UPDATE_PAST_SALARY_PERIOD },
		{ label: t('permission.action.retry_upload'), value: PermissionAction.RETRY_UPLOAD },
		{ label: t('permission.action.update_status'), value: PermissionAction.UPDATE_STATUS },
		{ label: t('permission.action.update_concept'), value: PermissionAction.UPDATE_CONCEPT },
		{ label: t('permission.action.delete_concept'), value: PermissionAction.DELETE_CONCEPT },
		{ label: t('permission.action.submit'), value: PermissionAction.SUBMIT },
		{ label: t('permission.action.validate'), value: PermissionAction.VALIDATE },
		{ label: t('permission.action.resolve'), value: PermissionAction.RESOLVE },
		{ label: t('permission.action.read_all'), value: PermissionAction.READ_ALL },
		{ label: t('permission.action.delete_past_cancel_date'), value: PermissionAction.DELETE_PAST_CANCEL_DATE },
		{ label: t('permission.action.delete_first_of_month'), value: PermissionAction.DELETE_FIRST_OF_MONTH },
		{ label: t('permission.action.read_past'), value: PermissionAction.READ_PAST },
		{ label: t('permission.action.to_ongoing'), value: PermissionAction.TO_ONGOING },
		{
			label: t('permission.action.ignore_max_period_in_temporary_contract'),
			value: PermissionAction.IGNORE_MAX_PERIOD_IN_TEMPORARY_CONTRACT,
		},
	]
})

export function getPermissionActionLabel(action: PermissionAction | string): string | null {
	return PERMISSION_ACTION_OPTIONS.value.find((option) => option.value === action)?.label || null
}
