import { Language } from '@/constants/language.enum.ts'
import { StaffType } from '@/constants/staffType.enum.ts'
import { StaffBuilder } from '@/models/staff/staff.builder.ts'
import type { Staff } from '@/models/staff/staff.type.ts'
import type { User } from '@/models/user.type.ts'
import { UuidUtil } from '@/utils/uuid.util.ts'

export class UserBuilder {
	value: User = {
		uuid: UuidUtil.getRandom<string>(),
		createdAt: new Date(),
		updatedAt: new Date(),
		type: StaffType.STAFF,
		email: 'email',
		emailVerifiedAt: new Date(),
		firstName: 'First',
		lastName: 'Last',
		locale: Language.NL,
		staff: new StaffBuilder().build(),
	}

	constructor() {}

	build(): User {
		return this.value
	}

	withUuid(uuid: string): UserBuilder {
		this.value.uuid = uuid
		return this
	}

	withCreatedAt(createdAt: Date): UserBuilder {
		this.value.createdAt = createdAt
		return this
	}

	withUpdatedAt(updatedAt: Date): UserBuilder {
		this.value.updatedAt = updatedAt
		return this
	}

	withType(type: StaffType): UserBuilder {
		this.value.type = type
		return this
	}

	withEmail(email: string): UserBuilder {
		this.value.email = email
		return this
	}

	withEmailVerifiedAt(emailVerifiedAt: Date): UserBuilder {
		this.value.emailVerifiedAt = emailVerifiedAt
		return this
	}

	withFirstName(firstName: string): UserBuilder {
		this.value.firstName = firstName
		return this
	}

	withLastName(lastName: string): UserBuilder {
		this.value.lastName = lastName
		return this
	}

	withLocale(locale: Language): UserBuilder {
		this.value.locale = locale
		return this
	}

	withStaff(staff: Staff): UserBuilder {
		this.value.staff = staff
		return this
	}
}
