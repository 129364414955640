import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const matchingCenterRoutes: RouteRecordRaw[] = [
	{
		beforeEnter: permissionMiddleware([Permission.MATCHING_CENTER_READ]),
		component: async () => import('@/modules/matching-center/views/MatchingCenterView.vue'),
		name: ROUTE_NAME.MATCHING_CENTER.INDEX,
		path: '/matching-center',
		redirect: { name: ROUTE_NAME.MATCHING_CENTER.EMPLOYEES },
		children: [
			{
				component: async () => import('@/modules/matching-center/views/MatchingCenterEmployeesView.vue'),
				name: ROUTE_NAME.MATCHING_CENTER.EMPLOYEES,
				path: 'employees',
			},
			{
				component: async () => import('@/modules/matching-center/views/MatchingCenterCustomersView.vue'),
				name: ROUTE_NAME.MATCHING_CENTER.CUSTOMERS,
				path: 'customers',
			},
			{
				component: async () => import('@/views/UnderConstructionView.vue'),
				name: ROUTE_NAME.MATCHING_CENTER.RESERVATIONS,
				path: 'reservations',
			},
		],
	},
]
