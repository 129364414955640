import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import { i18nPlugin } from '@/plugins/i18n.plugin'
import ROUTE_NAME from '@/router/routeName'

export const employeesRoutes: RouteRecordRaw[] = [
	{
		beforeEnter: permissionMiddleware([Permission.EMPLOYEE_READ]),
		name: ROUTE_NAME.EMPLOYEES.INDEX,
		path: '/employees',
		component: () => import('@/modules/employees/views/EmployeesView.vue'),
	},
	{
		beforeEnter: permissionMiddleware([Permission.EMPLOYEE_READ]),
		component: () => import('@/modules/employees/views/detail/EmployeesDetailView.vue'),
		name: ROUTE_NAME.EMPLOYEES.DETAIL.INDEX,
		path: '/employees/:uuid',
		redirect: { name: ROUTE_NAME.EMPLOYEES.DETAIL.OVERVIEW.INDEX },
		children: [
			{
				component: () => import('@/modules/employees/views/detail/overview/EmployeesDetailOverviewView.vue'),
				meta: {
					name: i18nPlugin.global.t('shared.employees'),
				},
				name: ROUTE_NAME.EMPLOYEES.DETAIL.OVERVIEW.INDEX,
				path: 'overview',
				redirect: { name: ROUTE_NAME.EMPLOYEES.DETAIL.OVERVIEW.DASHBOARD },
				children: [
					{
						component: () =>
							import('@/modules/employees/views/detail/overview/EmployeesDetailOverviewDashboardView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.OVERVIEW.DASHBOARD,
						path: 'dashboard',
					},
					{
						component: () =>
							import('@/modules/employees/views/detail/overview/EmployeesDetailOverviewLogbooksView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.OVERVIEW.LOGBOOKS,
						beforeEnter: permissionMiddleware([Permission.LOGBOOK_READ]),
						path: 'logbook',
					},
				],
			},
			{
				component: () => import('@/modules/employees/views/detail/planning/EmployeesDetailPlanningView.vue'),
				name: ROUTE_NAME.EMPLOYEES.DETAIL.PLANNING.INDEX,
				path: 'planning',
				redirect: {
					name: ROUTE_NAME.EMPLOYEES.DETAIL.PLANNING.CALENDAR,
				},
				children: [
					{
						component: () =>
							import('@/modules/employees/views/detail/planning/EmployeesDetailPlanningCalendarView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.PLANNING.CALENDAR,
						path: 'calendar',
					},
					{
						component: () =>
							import('@/modules/employees/views/detail/planning/EmployeesDetailPlanningAvailabilitiesView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.PLANNING.AVAILABILITIES,
						path: 'availabilities',
					},
					{
						component: () =>
							import('@/modules/employees/views/detail/planning/EmployeesDetailPlanningCustomersView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.PLANNING.CUSTOMERS,
						path: 'customers',
					},
				],
			},
			{
				component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoView.vue'),
				name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.INDEX,
				path: 'info',
				redirect: { name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.PERSONAL },
				children: [
					{
						component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoPersonalView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.PERSONAL,
						path: 'personal',
					},
					{
						component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoProfessionalView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.PROFESSIONAL,
						path: 'professional',
					},
					{
						component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoFiscalView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.FISCAL,
						path: 'fiscal',
					},
					{
						component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoSubsidiesView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.SUBSIDIES,
						path: 'subsidies',
					},
					{
						component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoApplicationView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.APPLICATION,
						path: 'application',
					},
					{
						component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoLeadView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.LEAD,
						path: 'lead',
					},
					{
						component: () =>
							import('@/modules/employees/views/detail/info/EmployeesDetailInfoProfessionalPluxeeLoginView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.PLUXEE_LOGIN,
						path: 'pluxee-login-login',
					},
					{
						component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoCancellationView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.CANCELLATION,
						path: 'cancellation',
					},
					{
						component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoRejectionView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.REJECTION,
						path: 'rejection',
					},
				],
			},
			{
				beforeEnter: permissionMiddleware([Permission.EMPLOYEE_CONTRACT_READ]),
				name: ROUTE_NAME.EMPLOYEES.DETAIL.CONTRACTS.INDEX,
				path: 'contracts',
				redirect: { name: ROUTE_NAME.EMPLOYEES.DETAIL.CONTRACTS.OVERVIEW },
				children: [
					{
						component: () => import('@/modules/employees/views/detail/contracts/EmployeesDetailContractsView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.CONTRACTS.OVERVIEW,
						path: 'overview',
					},
					{
						component: () =>
							import('@/modules/employees/views/detail/contracts/EmployeesDetailContractsDetailView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.CONTRACTS.DETAIL,
						path: ':contractUuid',
					},
					{
						component: () =>
							import(
								'@/modules/employees/views/detail/contracts/dimona-declarations/EmployeeDetailContractDimonaDeclarationView.vue'
							),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.CONTRACTS.DIMONA_DECLARATIONS_INDEX,
						path: ':contractUuid/declarations',
					},
				],
			},
			{
				component: () => import('@/modules/employees/views/detail/compensation/EmployeesDetailCompensationView.vue'),
				name: ROUTE_NAME.EMPLOYEES.DETAIL.COMPENSATION.INDEX,
				path: 'compensation',
				redirect: { name: ROUTE_NAME.EMPLOYEES.DETAIL.COMPENSATION.WAGE },
				children: [
					{
						component: () =>
							import('@/modules/employees/views/detail/compensation/EmployeesDetailCompensationWageView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.COMPENSATION.WAGE,
						path: 'wage',
					},
					{
						component: () =>
							import('@/modules/employees/views/detail/compensation/EmployeesDetailCompensationWageBenefitView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.COMPENSATION.WAGE_BENEFITS,
						path: 'wage-benefits',
					},
					{
						component: () =>
							import(
								'@/modules/employees/views/detail/compensation/EmployeesDetailCompensationAdvancePaymentRejectionsView.vue'
							),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.COMPENSATION.ADVANCE,
						path: 'advance',
					},
					{
						component: () =>
							import(
								'@/modules/employees/views/detail/compensation/EmployeesDetailCompensationEconomicUnemploymentView.vue'
							),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.COMPENSATION.ECONOMIC_UNEMPLOYMENT,
						path: 'economic-unemployment',
					},
					{
						component: () =>
							import('@/modules/employees/views/detail/compensation/EmployeeDetailCompensationReferralPremiumView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.COMPENSATION.REFERRAL_PREMIUMS,
						path: 'referral_premiums',
					},
					{
						component: () =>
							import('@/modules/employees/views/detail/compensation/EmployeesDetailCompensationTaxWithholdingView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.COMPENSATION.TAX_WITHHOLDING,
						path: 'tax-withholdings',
					},
				],
			},
			{
				component: () => import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesView.vue'),
				name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.INDEX,
				path: 'absences',
				redirect: { name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.ILLNESS },
				children: [
					{
						component: () => import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesIllnessView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.ILLNESS,
						path: 'illness',
					},
					{
						component: () => import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesLeaveView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.LEAVE,
						path: 'leave',
					},
					{
						component: () =>
							import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesPregnancyView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.PREGNANCY,
						path: 'pregnancy',
					},
					{
						component: () =>
							import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesAccidentsView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.ACCIDENTS,
						path: 'accidents',
					},
					{
						component: () =>
							import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesPartTimeView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.PART_TIME,
						path: 'part-time',
					},
					{
						component: () => import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesOtherView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.OTHER,
						path: 'other',
					},
					{
						component: () =>
							import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesCountersView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.COUNTERS,
						path: 'counters',
					},
				],
			},
			{
				component: () =>
					import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesPartTimeUpdateView.vue'),
				name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.PART_TIME_UPDATE,
				path: 'part-time/:absenceUuid',
			},
			{
				component: () => import('@/modules/employees/views/detail/documents/EmployeeDetailDocumentsView.vue'),
				name: ROUTE_NAME.EMPLOYEES.DETAIL.DOCUMENTS.INDEX,
				path: 'documents',
			},
			{
				component: () => import('@/modules/employees/views/detail/notifications/EmployeesDetailNotificationsView.vue'),
				name: ROUTE_NAME.EMPLOYEES.DETAIL.NOTIFICATIONS.INDEX,
				path: 'notification',
				redirect: {
					name: ROUTE_NAME.EMPLOYEES.DETAIL.NOTIFICATIONS.DAMAGE_CLAIMS,
				},
				children: [
					{
						component: async () =>
							import('@/modules/employees/views/detail/notifications/EmployeesDetailNotificationsClaimsView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.NOTIFICATIONS.DAMAGE_CLAIMS,
						path: 'damage-claims',
					},
					{
						component: () =>
							import('@/modules/employees/views/detail/notifications/EmployeesDetailNotificationsAccidentsView.vue'),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.NOTIFICATIONS.WORK_ACCIDENTS,
						path: 'work-accidents',
					},
				],
			},
			{
				component: () => import('@/modules/employees/views/detail/service-checks/EmployeesDetailServiceChecksView.vue'),
				name: ROUTE_NAME.EMPLOYEES.DETAIL.SERVICE_CHECKS.INDEX,
				beforeEnter: permissionMiddleware([Permission.CHEQUE_READ]),
				path: 'service-checks',
				redirect: {
					name: ROUTE_NAME.EMPLOYEES.DETAIL.SERVICE_CHECKS.SHORTAGES.INDEX,
				},
				children: [
					{
						component: () =>
							import(
								'@/modules/employees/views/detail/service-checks/treatable/EmployeesDetailServiceChecksTreatableView.vue'
							),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.SERVICE_CHECKS.TREATABLE.INDEX,
						path: 'treatable',
					},
					{
						component: () =>
							import(
								'@/modules/employees/views/detail/service-checks/shortages/EmployeesDetailServiceChecksShortagesView.vue'
							),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.SERVICE_CHECKS.SHORTAGES.INDEX,
						path: 'shortages',
					},
					{
						component: () =>
							import(
								'@/modules/employees/views/detail/service-checks/checks/EmployeesDetailServiceChecksChecksView.vue'
							),
						name: ROUTE_NAME.EMPLOYEES.DETAIL.SERVICE_CHECKS.CHECKS.INDEX,
						path: 'checks',
					},
				],
			},
		],
	},
	{
		beforeEnter: permissionMiddleware([Permission.EMPLOYEE_READ, Permission.EMPLOYEE_CREATE]),
		component: () => import('@/modules/employees/views/application/EmployeesApplicationView.vue'),
		name: ROUTE_NAME.EMPLOYEES.APPLICATION.INDEX,
		path: 'employees/:uuid/application',
	},
	{
		beforeEnter: permissionMiddleware([
			Permission.EMPLOYEE_CONTRACT_READ,
			Permission.EMPLOYEE_CONTRACT_CREATE,
			Permission.EMPLOYEE_CONTRACT_UPDATE,
		]),
		component: () => import('@/modules/employees/views/contract/EmployeesContractConceptView.vue'),
		name: ROUTE_NAME.EMPLOYEES.DETAIL.CONTRACTS.CONCEPT,
		path: 'employees/:uuid/contract/concept',
	},
	{
		beforeEnter: permissionMiddleware([Permission.EMPLOYEE_CONTRACT_READ]),
		component: () => import('@/modules/employees/views/contract/EmployeesContractTimetableCorrectionConceptView.vue'),
		name: ROUTE_NAME.EMPLOYEES.DETAIL.CONTRACTS.TIMETABLE_CORRECTION_CONCEPT,
		path: 'employees/:uuid/contract/:contractUuid/timetables/:timetableUuid/timetable-correction-concept',
	},
	{
		beforeEnter: permissionMiddleware([Permission.EMPLOYEE_CONTRACT_READ]),
		component: () => import('@/modules/employees/views/contract/EmployeesContractTimetableConceptView.vue'),
		name: ROUTE_NAME.EMPLOYEES.DETAIL.CONTRACTS.TIMETABLE_CONCEPT,
		path: 'employees/:uuid/contract/:contractUuid/timetable-concept',
	},
]
