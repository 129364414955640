import { Permission } from '@/constants/permission.enum.ts'
import type { DepartmentStaff, Staff } from '@/models/staff/staff.type'
import type { User } from '@/models/user.type'
import type { UserRole } from '@/modules/settings/models/user-role/userRole.type'
import { UuidUtil } from '@/utils/uuid.util.ts'

export class StaffBuilder {
	value: Staff = {
		uuid: UuidUtil.getRandom<string>(),
		createdAt: new Date(),
		updatedAt: new Date(),
		jobTitle: null,
		role: {
			permissions: [Permission.ADMIN],
			name: 'test',
			uuid: UuidUtil.getRandom<string>(),
		},
		user: undefined,
		departmentStaff: [],
	}

	constructor() {}

	build(): Staff {
		return this.value
	}

	withUuid(uuid: string): StaffBuilder {
		this.value.uuid = uuid
		return this
	}

	withCreatedAt(createdAt: Date): StaffBuilder {
		this.value.createdAt = createdAt
		return this
	}

	withUpdatedAt(updatedAt: Date): StaffBuilder {
		this.value.updatedAt = updatedAt
		return this
	}

	withJobTitle(jobTitle: string | null): StaffBuilder {
		this.value.jobTitle = jobTitle
		return this
	}

	withRole(role: UserRole | null): StaffBuilder {
		this.value.role = role
		return this
	}

	withUser(user: User): StaffBuilder {
		this.value.user = user
		return this
	}

	withDepartmentStaff(departmentStaff: DepartmentStaff[]): StaffBuilder {
		this.value.departmentStaff = departmentStaff
		return this
	}
}
