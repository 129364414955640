import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const settingsRoutes: RouteRecordRaw[] = [
	{
		component: () => import('@/modules/settings/views/subscription/SettingsSubscriptionsDetailView.vue'),
		name: ROUTE_NAME.SETTINGS.SUBSCRIPTIONS.CONFIGURATION_DETAIL,
		path: '/settings/administrative-compensation/configuration/:uuid',
		beforeEnter: permissionMiddleware([Permission.SUBSCRIPTION_SETTINGS_READ]),
	},
	{
		component: () => import('@/modules/settings/views/businesses/offices/SettingsBusinessesOfficeDetailView.vue'),
		name: ROUTE_NAME.SETTINGS.BUSINESSES.OFFICE_DETAIL,
		path: 'settings/businesses/offices/:uuid',
		beforeEnter: permissionMiddleware([Permission.OFFICE_READ]),
	},
	{
		component: () =>
			import('@/modules/settings/views/businesses/origin-companies/SettingsBusinessOriginCompanyDetailView.vue'),
		name: ROUTE_NAME.SETTINGS.BUSINESSES.ORIGIN_COMPANY_DETAIL,
		path: 'settings/businesses/origin_companies/:uuid',
		beforeEnter: permissionMiddleware([Permission.ORIGIN_COMPANY_READ]),
	},
	{
		component: () => import('@/modules/settings/views/businesses/payrolls/SettingsBusinessesPayrollDetailView.vue'),
		name: ROUTE_NAME.SETTINGS.BUSINESSES.PAYROLL_DETAIL.INDEX,
		path: 'settings/businesses/payrolls/:uuid',
		redirect: { name: ROUTE_NAME.SETTINGS.BUSINESSES.PAYROLL_DETAIL.OFFICES },
		beforeEnter: permissionMiddleware([Permission.PAYROLL_READ]),
		children: [
			{
				component: () =>
					import('@/modules/settings/views/businesses/payrolls/SettingsBusinessesPayrollDetailOfficesView.vue'),
				name: ROUTE_NAME.SETTINGS.BUSINESSES.PAYROLL_DETAIL.OFFICES,
				path: 'offices',
			},
			{
				component: () =>
					import('@/modules/settings/views/businesses/payrolls/SettingsBusinessesPayrollDetailEmployeesView.vue'),
				name: ROUTE_NAME.SETTINGS.BUSINESSES.PAYROLL_DETAIL.EMPLOYEES,
				path: 'employees',
			},
		],
	},
	{
		component: () => import('@/modules/settings/views/subsidy/SettingsSubsidiesDetailView.vue'),
		name: ROUTE_NAME.SETTINGS.SUBSIDY.DETAIL,
		path: 'settings/subsidies/subsidy/:uuid',
	},
	{
		component: () => import('@/modules/settings/views/subsidy/SettingsSubsidiesCreateSubsidyView.vue'),
		name: ROUTE_NAME.SETTINGS.SUBSIDY.CREATE,
		path: 'settings/subsidies/create',
	},
	{
		component: () =>
			import('@/modules/settings/views/businesses/departments/SettingsBusinessesDepartmentDetailView.vue'),
		name: ROUTE_NAME.SETTINGS.BUSINESSES.DEPARTMENT_DETAIL,
		path: 'settings/businesses/departments/:uuid',
		beforeEnter: permissionMiddleware([Permission.DEPARTMENT_READ]),
	},
	{
		component: () => import('@/modules/settings/views/businesses/districts/SettingsBusinessesDistrictDetailView.vue'),
		name: ROUTE_NAME.SETTINGS.BUSINESSES.DISTRICT_DETAIL_NO_DM,
		path: 'settings/businesses/districts/no-dm',
		beforeEnter: permissionMiddleware([Permission.DISTRICT_READ]),
	},
	{
		component: () => import('@/modules/settings/views/businesses/districts/SettingsBusinessesDistrictDetailView.vue'),
		name: ROUTE_NAME.SETTINGS.BUSINESSES.DISTRICT_DETAIL,
		path: 'settings/businesses/districts/:uuid',
		beforeEnter: permissionMiddleware([Permission.DEPARTMENT_READ]),
	},
	{
		component: () => import('@/modules/settings/views/users/SettingsUsersStaffDetailView.vue'),
		name: ROUTE_NAME.SETTINGS.USERS.STAFF_DETAIL,
		path: 'settings/users/staff/:uuid',
		beforeEnter: permissionMiddleware([Permission.STAFF_READ]),
	},
	{
		component: () => import('@/modules/settings/views/SettingsView.vue'),
		name: ROUTE_NAME.SETTINGS.INDEX,
		path: '/settings',
		redirect: { name: ROUTE_NAME.SETTINGS.USERS.INDEX },
		beforeEnter: permissionMiddleware([Permission.SETTINGS_READ]),
		children: [
			{
				component: () => import('@/modules/settings/views/users/SettingsUsersView.vue'),
				name: ROUTE_NAME.SETTINGS.USERS.INDEX,
				path: 'users',
				redirect: { name: ROUTE_NAME.SETTINGS.USERS.STAFF },
				children: [
					{
						component: () => import('@/modules/settings/views/users/SettingsUsersStaffView.vue'),
						name: ROUTE_NAME.SETTINGS.USERS.STAFF,
						path: 'staff',
					},
					{
						component: () => import('@/modules/settings/views/users/SettingsUsersRolesAndPermissionsView.vue'),
						name: ROUTE_NAME.SETTINGS.USERS.ROLES_AND_PERMISSIONS,
						beforeEnter: permissionMiddleware([Permission.ROLES_READ, Permission.PERMISSION_READ]),
						path: 'permissions',
					},
				],
			},
			{
				component: () => import('@/modules/settings/views/businesses/SettingsBusinessesView.vue'),
				name: ROUTE_NAME.SETTINGS.BUSINESSES.INDEX,
				path: 'businesses',
				redirect: { name: ROUTE_NAME.SETTINGS.BUSINESSES.PAYROLLS },
				children: [
					{
						component: () => import('@/modules/settings/views/businesses/payrolls/SettingsBusinessesPayrollsView.vue'),
						name: ROUTE_NAME.SETTINGS.BUSINESSES.PAYROLLS,
						beforeEnter: permissionMiddleware([Permission.PAYROLL_READ]),
						path: 'payroll',
					},
					{
						component: async () =>
							import('@/modules/settings/views/businesses/districts/SettingsBusinessesDistrictsView.vue'),
						name: ROUTE_NAME.SETTINGS.BUSINESSES.DISTRICTS,
						beforeEnter: permissionMiddleware([Permission.DISTRICT_READ]),
						path: 'districts',
					},
					{
						component: () => import('@/modules/settings/views/businesses/offices/SettingsBusinessesOfficesView.vue'),
						name: ROUTE_NAME.SETTINGS.BUSINESSES.OFFICES,
						beforeEnter: permissionMiddleware([Permission.OFFICE_READ]),
						path: 'offices',
					},
					{
						component: () =>
							import('@/modules/settings/views/businesses/departments/SettingsBusinessesDepartmentView.vue'),
						name: ROUTE_NAME.SETTINGS.BUSINESSES.DEPARTMENTS,
						beforeEnter: permissionMiddleware([Permission.DEPARTMENT_READ]),
						path: 'departments',
					},
					{
						component: () =>
							import('@/modules/settings/views/businesses/origin-companies/SettingsBusinessesOriginCompaniesView.vue'),
						name: ROUTE_NAME.SETTINGS.BUSINESSES.ORIGIN_COMPANIES,
						beforeEnter: permissionMiddleware([Permission.ORIGIN_COMPANY_READ]),
						path: 'origin-companies',
					},
				],
			},
			{
				component: () => import('@/modules/settings/views/application/SettingsApplicationView.vue'),
				name: ROUTE_NAME.SETTINGS.APPLICATION.INDEX,
				path: 'application',
				redirect: { name: ROUTE_NAME.SETTINGS.APPLICATION.FAQ },
				children: [
					{
						component: () => import('@/modules/settings/views/application/SettingsApplicationFaqView.vue'),
						name: ROUTE_NAME.SETTINGS.APPLICATION.FAQ,
						beforeEnter: permissionMiddleware([Permission.FAQ_READ]),
						path: 'faq',
					},
					{
						component: () => import('@/modules/settings/views/application/SettingsApplicationInfoBubblesView.vue'),
						name: ROUTE_NAME.SETTINGS.APPLICATION.INFO_BUBBLES,
						beforeEnter: permissionMiddleware([Permission.INFO_BUBBLES_READ]),
						path: 'information',
					},
					{
						component: () => import('@/modules/settings/views/application/SettingsApplicationPlanningRemarksView.vue'),
						name: ROUTE_NAME.SETTINGS.APPLICATION.PLANNING_REMARKS,
						beforeEnter: permissionMiddleware([Permission.PLANNING_READ]),
						path: 'planning-remarks',
					},
					{
						component: () => import('@/modules/settings/views/application/SettingsApplicationTermsOfServiceView.vue'),
						name: ROUTE_NAME.SETTINGS.APPLICATION.TERMS_OF_SERVICE,
						path: 'terms-of-service',
					},
				],
			},
			{
				component: () => import('@/modules/settings/views/subsidy/SettingsSubsidiesView.vue'),
				name: ROUTE_NAME.SETTINGS.SUBSIDY.INDEX,
				path: 'subsidies',
			},
			{
				component: () => import('@/modules/settings/views/compensation/SettingsCompensationView.vue'),
				name: ROUTE_NAME.SETTINGS.COMPENSATION.INDEX,
				path: 'compensation',
				redirect: { name: ROUTE_NAME.SETTINGS.COMPENSATION.BENEFIT_CODES },
				children: [
					{
						component: () => import('@/modules/settings/views/compensation/SettingsCompensationBenefitCodeView.vue'),
						name: ROUTE_NAME.SETTINGS.COMPENSATION.BENEFIT_CODES,
						beforeEnter: permissionMiddleware([Permission.BENEFIT_CODE_READ]),
						path: 'benefit-code',
					},
					{
						component: () => import('@//modules/settings/views/compensation/SettingsCompensationBaremaView.vue'),
						name: ROUTE_NAME.SETTINGS.COMPENSATION.BAREMA,
						beforeEnter: permissionMiddleware([Permission.BAREMA_READ]),
						path: 'barema',
					},
					{
						component: () => import('@/modules/settings/views/compensation/SettingsCompensationAdvanceAmountView.vue'),
						name: ROUTE_NAME.SETTINGS.COMPENSATION.ADVANCE_AMOUNT,
						beforeEnter: permissionMiddleware([Permission.SALARY_READ]),
						path: 'advance-payment-rejection-amount',
					},
					{
						component: () => import('@/modules/settings/views/compensation/SettingsCompensationAbsenceCodesView.vue'),
						name: ROUTE_NAME.SETTINGS.COMPENSATION.ABSENCE_CODES,
						beforeEnter: permissionMiddleware([Permission.ABSENCE_CODE_READ]),
						path: 'absence-codes',
					},
					{
						component: async () => import('@/modules/settings/views/compensation/SettingsCompensationHolidaysView.vue'),
						name: ROUTE_NAME.SETTINGS.COMPENSATION.HOLIDAYS,
						beforeEnter: permissionMiddleware([Permission.HOLIDAY_READ]),
						path: 'holidays',
					},
					{
						component: async () =>
							import('@/modules/settings/views/compensation/SettingsCompensationEconomicUnemploymentPeriodsView.vue'),
						name: ROUTE_NAME.SETTINGS.COMPENSATION.PERIODS_ECONOMIC_UNEMPLOYMENT,
						beforeEnter: permissionMiddleware([Permission.ECONOMIC_UNEMPLOYMENT_PERIODS_READ]),
						path: 'periods_economic_unemployment',
					},
				],
			},
			{
				component: () => import('@/modules/settings/views/subscription/SettingsSubscriptionsView.vue'),
				name: ROUTE_NAME.SETTINGS.SUBSCRIPTIONS.INDEX,
				beforeEnter: permissionMiddleware([Permission.SUBSCRIPTION_SETTINGS_READ]),
				path: 'subscriptions',
			},
			{
				component: () => import('@/modules/settings/views/synchronisations/SettingsSynchronisationsView.vue'),
				name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.INDEX,
				path: 'synchronisations',
				redirect: { name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.DIMONA },
				beforeEnter: permissionMiddleware([Permission.SUBSCRIPTION_SETTINGS_READ]),
				children: [
					{
						component: () =>
							import('@/modules/settings/views/synchronisations/dimona/SettingsSynchronisationsDimonaView.vue'),
						name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.DIMONA,
						path: 'dimona-declaration',
					},
					{
						component: () =>
							import('@/modules/settings/views/synchronisations/partena/SettingsSynchronisationsPartenaView.vue'),
						name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.PARTENA.INDEX,
						path: 'partena',
						redirect: {
							name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.PARTENA.TIME_FILES,
						},
						children: [
							{
								component: () =>
									import(
										'@/modules/settings/views/synchronisations/partena/SettingsSynchronisationsPartenaTimeFilesView.vue'
									),
								name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.PARTENA.TIME_FILES,
								path: 'time-files',
							},
							{
								component: () =>
									import(
										'@/modules/settings/views/synchronisations/partena/SettingsSynchronisationPartenaErpFilesView.vue'
									),
								name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.PARTENA.ERP_FILES,
								path: 'erp-files',
							},
							{
								component: () =>
									import(
										'@/modules/settings/views/synchronisations/partena/SettingsSynchronisationPartenaRecordsView.vue'
									),
								name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.PARTENA.RECORDS,
								path: 'records',
							},
							{
								component: () =>
									import(
										'@/modules/settings/views/synchronisations/partena/SettingsSynchronisationPartenaWarningsView.vue'
									),
								name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.PARTENA.WARNINGS,
								path: 'warnings',
							},
							{
								component: () =>
									import(
										'@/modules/settings/views/synchronisations/partena/SettingsSynchronisationPartenaVariableSchedulesView.vue'
									),
								name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.PARTENA.VARIABLE_SCHEDULES,
								path: 'variable-schedules',
							},
							{
								component: () =>
									import(
										'@/modules/settings/views/synchronisations/partena/SettingsSynchronisationPartenaWageFilesView.vue'
									),
								name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.PARTENA.WAGE_FILES,
								path: 'wage-files',
							},
						],
					},
					{
						component: () =>
							import('@/modules/settings/views/synchronisations/rsz/SettingsSynchronisationsRszView.vue'),
						name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.RSZ.INDEX,
						path: 'rsz',
						redirect: {
							name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.RSZ.NOTIFICATIONS.INDEX,
						},
						children: [
							{
								component: () =>
									import(
										'@/modules/settings/views/synchronisations/rsz/notifications/SettingsSynchronisationsRszNotificationsView.vue'
									),
								name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.RSZ.NOTIFICATIONS.INDEX,
								path: 'notification',
							},
							{
								component: () =>
									import(
										'@/modules/settings/views/synchronisations/rsz/declarations/SettingsSynchronisationsRszDeclarationsView.vue'
									),
								name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.RSZ.DECLARATIONS.INDEX,
								path: 'declarations',
							},
							{
								component: () =>
									import(
										'@/modules/settings/views/synchronisations/rsz/validation-book/SettingsSynchronisationsRszValidationBookView.vue'
									),
								name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.RSZ.VALIDATION_BOOK.INDEX,
								path: 'validation-book',
							},
						],
					},

					{
						component: () =>
							import('@/modules/settings/views/synchronisations/emma/SettingsSynchronisationsEmmaView.vue'),
						name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.EMMA,
						path: 'emma',
					},
					{
						component: () =>
							import('@/modules/settings/views/synchronisations/cron-jobs/SettingsSynchronisationsCronJobsView.vue'),
						name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.CRON_JOB.INDEX,
						path: 'cron-job',
					},
					{
						component: () =>
							import('@/modules/settings/views/synchronisations/jobs/SettingsSynchronisationsJobsView.vue'),
						name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.JOB.INDEX,
						redirect: {
							name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.JOB.CURRENT,
						},
						path: 'job',
						children: [
							{
								component: () =>
									import('@/modules/settings/views/synchronisations/jobs/SettingsSynchronisationsJobsActiveView.vue'),
								name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.JOB.CURRENT,
								path: 'current',
							},
							{
								component: () =>
									import('@/modules/settings/views/synchronisations/jobs/SettingsSynchronisationsJobsArchivedView.vue'),
								name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.JOB.ARCHIVED,
								path: 'archived',
							},
						],
					},
				],
			},

			{
				component: () => import('@/modules/settings/views/service-checks/SettingsServiceChecksView.vue'),
				name: ROUTE_NAME.SETTINGS.SERVICE_CHECKS.INDEX,
				path: 'cheques',
				redirect: {
					name: ROUTE_NAME.SETTINGS.SERVICE_CHECKS.REIMBURSEMENT_VALUE,
				},
				beforeEnter: permissionMiddleware([Permission.CHEQUE_SETTINGS]),
				children: [
					{
						component: () =>
							import(
								'@/modules/settings/views/service-checks/reimbursements/SettingsServiceChecksReimbursementsView.vue'
							),
						name: ROUTE_NAME.SETTINGS.SERVICE_CHECKS.REIMBURSEMENT_VALUE,
						path: 'reimbursement',
					},
				],
			},
		],
	},
	{
		component: () =>
			import(
				'@/modules/settings/views/synchronisations/rsz/notifications/SettingsSynchronisationsRszNotificationsDetailView.vue'
			),
		name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.RSZ.NOTIFICATIONS.DETAIL,
		path: 'settings/synchronisations/rsz/notification/:uuid',
		beforeEnter: permissionMiddleware([Permission.SUBSCRIPTION_SETTINGS_READ]),
	},
	{
		component: () =>
			import(
				'@/modules/settings/views/synchronisations/partena/SettingsSynchronisationsPartenaTimeFilesDetailView.vue'
			),
		name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.PARTENA.TIME_FILES_DETAIL,
		path: 'settings/synchronisations/partena/time-files/detail/:uuid',
		beforeEnter: permissionMiddleware([Permission.SUBSCRIPTION_SETTINGS_READ]),
	},
	{
		component: () =>
			import('@/modules/settings/views/synchronisations/partena/SettingsSynchronisationPartenaErpFilesDetailView.vue'),
		name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.PARTENA.ERP_FILES_DETAIL,
		path: 'settings/synchronisations/partena/erp-files/detail/:uuid',
		beforeEnter: permissionMiddleware([Permission.SUBSCRIPTION_SETTINGS_READ]),
	},
	{
		component: () =>
			import('@/modules/settings/views/synchronisations/partena/SettingsSynchronisationPartenaWageFilesDetailView.vue'),
		name: ROUTE_NAME.SETTINGS.SYNCHRONISATIONS.PARTENA.WAGE_FILES_DETAIL,
		path: 'settings/synchronisations/partena/wage-files/detail/:uuid',
		beforeEnter: permissionMiddleware([Permission.SUBSCRIPTION_SETTINGS_READ]),
	},
]
