/* eslint-disable camelcase */
import type { AxiosPromise } from 'axios'
import axios from 'axios'

import { API_BASE_URL } from '@/constants/environment.constant'
import httpClient from '@/http/httpClient'
import type { User } from '@/models/user.type'

export default class AuthenticationService {
	checkStatus(): AxiosPromise<{ status: string }> {
		return axios.get(API_BASE_URL)
	}

	getActiveDirectoryLoginUrl(): AxiosPromise<{ url: string }> {
		return httpClient.get('/auth/ad/signin')
	}

	getActiveDirectorySignOutUrl(): AxiosPromise<{ url: string }> {
		return httpClient.get('/auth/ad/signout')
	}

	getUserInfo(): AxiosPromise<User> {
		return httpClient.get(`/auth/userinfo`)
	}
}
