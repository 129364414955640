import axios from 'axios'

import { API_AUTH_URL, API_CLIENT_ID, API_CLIENT_SECRET } from '@/constants/environment.constant'

import { OAuth2VueClient } from './oAuth2VueClient'

export const oAuthClient = new OAuth2VueClient({
	axios: axios,
	clientId: API_CLIENT_ID,
	clientSecret: API_CLIENT_SECRET,
	tokenEndpoint: `${API_AUTH_URL}/auth/token`,
})
