import { StyleBuilder } from '@/builders/style.builder'
import type { DefaultThemeColor } from '@/themes/default/colors/defaultThemeColors.type'

export enum ComponentVariants {
	DASHED_TRANSLUCENT = 'dashed-translucent',
	DASHED = 'dashed',
	OUTLINE_TRANSLUCENT = 'outline-translucent',
	OUTLINE = 'outline',
	SOLID = 'solid',
	TEXT = 'text',
	TRANSLUCENT = 'translucent',
}

export type ComponentVariant =
	| 'dashed-translucent'
	| 'dashed'
	| 'ghost'
	| 'outline-translucent'
	| 'outline'
	| 'solid'
	| 'text'
	| 'translucent'

type VariantForColor = (variant: string, color: DefaultThemeColor) => string

const ComponentVariantsMap: Record<ComponentVariant, VariantForColor> = {
	['dashed']: (_variant: string, color: DefaultThemeColor) =>
		`bg-transparent text-${color} border-${color} border-dashed`,
	['dashed-translucent']: (_variant: string, color: DefaultThemeColor) =>
		`bg-${color} bg-opacity-10 text-${color} border-${color}/50 border-dashed`,
	['outline']: (_variant: string, color: DefaultThemeColor) => `bg-transparent text-${color} border-${color}`,
	['outline-translucent']: (_variant: string, color: DefaultThemeColor) =>
		`bg-${color} bg-opacity-10 text-${color} border-${color}/50`,
	['solid']: (_variant: string, color: DefaultThemeColor) => `bg-${color} text-white border-${color}`,
	['text']: (_variant: string, color: DefaultThemeColor) =>
		`bg-transparent hover:underline text-${color} border-transparent`,
	['ghost']: (_variant: string, color: DefaultThemeColor) => `hover:bg-${color}/10 text-${color} border-transparent`,
	['translucent']: (_variant: string, color: DefaultThemeColor) =>
		`bg-${color} bg-opacity-10 text-${color} border border-transparent hover:border-${color}/50`,
}

export enum ComponentShapes {
	'circle' = 'circle',
	'default' = 'default',
	'rounded-full' = 'rounded-full',
	'square-rounded' = 'square-rounded',
	'square' = 'square',
}

export type ComponentShape = 'circle' | 'default' | 'rounded-full' | 'square-rounded' | 'square'

const ComponentShapesMap: Record<ComponentShape, string> = {
	['circle']: 'rounded-full aspect-square',
	['default']: 'rounded-lg',
	['rounded-full']: 'rounded-full',
	['square']: 'rounded-none',
	['square-rounded']: 'rounded aspect-square',
}

export function useComponentVariants(): {
	getComponentStyle: (
		variant: ComponentVariant,
		color: DefaultThemeColor,
		shape: ComponentShape,
		disabled?: boolean
	) => string
} {
	const defaultStyle =
		'border relative font-base items-center justify-center text-center overflow-hidden transition-all duration-200 ease-in-out'

	function getVariantStyle(variant: ComponentVariant = 'solid', color: DefaultThemeColor = 'lima'): string {
		return ComponentVariantsMap[variant](variant, color)
	}

	function getShape(shape: ComponentShape = 'default'): string {
		return ComponentShapesMap[shape]
	}

	function getDisabledStyle(disabled = false): string {
		return disabled ? 'opacity-30 border-opacity-0 !border-transparent !cursor-not-allowed !pointer-event-none' : ''
	}

	function getComponentStyle(
		variant: ComponentVariant = 'solid',
		color: DefaultThemeColor = 'lima',
		shape: ComponentShape = 'default',
		disabled = false
	): string {
		return new StyleBuilder()
			.add(defaultStyle)
			.add(getVariantStyle(variant, color))
			.add(getShape(shape))
			.add(getDisabledStyle(disabled))
			.build()
	}

	return { getComponentStyle }
}
