import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const compensationsRoutes: RouteRecordRaw[] = [
	{
		children: [
			{
				children: [
					{
						component: () => import('@/modules/compensations/views/advances/CompensationsAdvancesStaffView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.ADVANCES.STAFF,
						path: 'staff',
					},
					{
						beforeEnter: permissionMiddleware([Permission.ADMIN]),
						component: () => import('@/modules/compensations/views/advances/CompensationsAdvancesAdminView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.ADVANCES.ADMIN,
						path: 'admin',
					},
				],
				name: ROUTE_NAME.COMPENSATIONS.ADVANCES.INDEX,
				path: 'advances',
				redirect: { name: ROUTE_NAME.COMPENSATIONS.ADVANCES.STAFF },
			},
			{
				children: [
					{
						component: () => import('@/modules/compensations/views/payments/staff/CompensationsPaymentsStaffView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.PAYMENTS.STAFF,
						path: 'staff',
					},
					{
						beforeEnter: permissionMiddleware([Permission.ADMIN]),
						component: () => import('@/modules/compensations/views/payments/admin/CompensationsPaymentsAdminView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.PAYMENTS.ADMIN,
						path: 'admin',
					},
				],
				name: ROUTE_NAME.COMPENSATIONS.PAYMENTS.INDEX,
				path: 'payments',
				redirect: { name: ROUTE_NAME.COMPENSATIONS.PAYMENTS.STAFF },
			},
			{
				name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.INDEX,
				path: 'corrections',
				redirect: { name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.IN_PROGRESS },
				component: () => import('@/modules/compensations/views/corrections/CompensationsCorrectionsView.vue'),
				children: [
					{
						component: () => import('@/modules/compensations/views/corrections/CompensationsCorrectionsOpenView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.IN_PROGRESS,
						path: 'in-progress',
					},
					{
						component: () =>
							import('@/modules/compensations/views/corrections/CompensationsCorrectionsRequestedView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.REQUESTED,
						path: 'requested',
					},
					{
						component: () =>
							import('@/modules/compensations/views/corrections/CompensationsCorrectionsApprovedView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.APPROVED,
						path: 'approved',
					},
					{
						component: () => import('@/modules/compensations/views/corrections/CompensationsCorrectionsExportView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.EXPORTED,
						path: 'exported',
					},
					{
						component: () =>
							import('@/modules/compensations/views/corrections/CompensationsCorrectionsRejectedView.vue'),
						name: ROUTE_NAME.COMPENSATIONS.CORRECTIONS.REJECTED,
						path: 'rejected',
					},
				],
			},
		],
		beforeEnter: permissionMiddleware([Permission.SALARY_READ]),
		component: async () => import('@/modules/compensations/views/CompensationsView.vue'),
		name: ROUTE_NAME.COMPENSATIONS.INDEX,
		path: '/compensations',
		redirect: { name: ROUTE_NAME.COMPENSATIONS.ADVANCES.INDEX },
	},
	{
		beforeEnter: permissionMiddleware([Permission.SALARY_READ]),
		component: () => import('@/modules/compensations/views/advances/CompensationsAdvancesDetailView.vue'),
		name: ROUTE_NAME.COMPENSATIONS.ADVANCES.DETAIL,
		path: '/compensations/advances/:employeeUuid',
	},
	{
		beforeEnter: permissionMiddleware([Permission.SALARY_READ]),
		component: () => import('@/modules/compensations/views/payments/CompensationsPaymentsDetailView.vue'),
		name: ROUTE_NAME.COMPENSATIONS.PAYMENTS.DETAIL,
		path: '/compensations/payments/:employeeUuid',
	},
]
