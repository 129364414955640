/* eslint-disable no-console */
export const API_BASE_URL = import.meta.env.API_BASE_URL
export const API_AUTH_URL = import.meta.env.API_AUTH_URL
export const API_CLIENT_ID = import.meta.env.API_CLIENT_ID
export const API_CLIENT_SECRET = import.meta.env.API_CLIENT_SECRET

export const SENTRY_DSN = import.meta.env.SENTRY_DSN
export const SENTRY_SAMPLE_RATE = import.meta.env.SENTRY_SAMPLE_RATE

export const MATOMO_URL = import.meta.env.MATOMO_URL
export const CURRENT_ENVIRONMENT = import.meta.env.ENVIRONMENT
export const GOOGLE_API_KEY = import.meta.env.GOOGLE_API_KEY
export const CURRENT_BUILD_NUMBER = BUILD_NUMBER
export const CURRENT_BUILD_COMMIT = BUILD_COMMIT
export const CURRENT_BUILD_TIMESTAMP = BUILD_TIMESTAMP

export function logBuildInformation(): void {
	if (CURRENT_BUILD_COMMIT === 'undefined') {
		return
	}

	console.groupCollapsed('Build Information')

	function logRow(label: string, value: string): void {
		console.info(`%c${label.padEnd(15)} %c${value}`, 'font-weight: bold;', 'font-weight: normal;')
	}

	logRow('Api Base URL', API_BASE_URL)
	console.info('')
	logRow('Environment', CURRENT_ENVIRONMENT)
	logRow('Build Number', CURRENT_BUILD_NUMBER)
	logRow('Build Commit', CURRENT_BUILD_COMMIT)
	logRow('Build Timestamp', CURRENT_BUILD_TIMESTAMP)

	console.groupEnd()
}
